import { isEnvironmentProductionLike, isTestEnvironmentProduction } from './helpers';

const production = {
  flowDiagram: false,
  containerFiles: true,
};

const staging = {
  ...production,
};

const queryStringFlippers = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const flipperParams = urlParams.get('flippers')?.split(',') || [];

  const urlFlippers = flipperParams.reduce((acc, flipper) => {
    acc[flipper] = true;
    return acc;
  }, {});

  return urlFlippers;
};

const getFlippers = () => {
  const flippers = isEnvironmentProductionLike() || isTestEnvironmentProduction() ? production : staging;
  return { ...flippers, ...queryStringFlippers() };
};

const flippers = getFlippers();

export default flippers;
