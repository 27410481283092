import Robot from 'types/Robot';
import RobotVersion from 'types/RobotVersion';
import MessageBodyComposer from 'utils/MessageBodyComposer';
import BaseApiService from './BaseApiService';

const ROBOT_API_HEADERS = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.acl.v1+json',
};

export default class RobotApiService extends BaseApiService {
  async getRobot(orgId, robotId): Promise<Robot> {
    const robotResponse = await this.fetchWithTokenRefresh(`/v1/orgs/${orgId}/robots/${robotId}`, {
      headers: ROBOT_API_HEADERS,
    });
    if (robotResponse.ok) {
      const robotJson = await robotResponse.json();
      const robot = this.deserialize(robotJson);
      return robot as Robot;
    }
    return Promise.reject(robotResponse.status);
  }

  async commitFlow(orgId, robotId, flowVersion, flowName, commitMsg, saveOutput): Promise<any> {
    const body = MessageBodyComposer.getCreateRobotVersionRequestBody(flowVersion, flowName, commitMsg, saveOutput);
    const createVersionResponse = await this.fetchWithTokenRefresh(`/v1/orgs/${orgId}/robots/${robotId}/versions`, {
      method: 'POST',
      headers: ROBOT_API_HEADERS,
      body: JSON.stringify(body),
    });
    if (createVersionResponse.ok) {
      const appJson = await createVersionResponse.json();
      const app = this.deserialize(appJson);
      const { ok, status, statusText } = createVersionResponse;
      const { id: newAppId, version: newAppVersion } = app as RobotVersion;
      return { ok, status, statusText, newAppId, newAppVersion };
    }
    return createVersionResponse;
  }

  async getApp(orgId, robotId, appId): Promise<any> {
    const appResponse = await this.fetchWithTokenRefresh(`/v1/orgs/${orgId}/robots/${robotId}/robot_apps/${appId}`, {
      headers: ROBOT_API_HEADERS,
    });
    if (appResponse.ok) {
      const appJson = await appResponse.json();
      return this.deserialize(appJson);
    }
    return Promise.reject(appResponse.status);
  }
}
