import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FlowInfo from 'types/FlowInfo';

const initialState: FlowInfo = {
  flowName: '',
  analyticId: '',
  saveOutput: false,
};

const flowInfoSlice = createSlice({
  name: 'flowInfo',
  initialState,
  reducers: {
    setFlowName(state, action: PayloadAction<string>) {
      state.flowName = action.payload;
    },
    setAnalyticId(state, action: PayloadAction<string>) {
      state.analyticId = action.payload;
    },
    setSaveOutput(state, action: PayloadAction<boolean>) {
      state.saveOutput = action.payload;
    },
  },
});

export const { setFlowName, setAnalyticId, setSaveOutput } = flowInfoSlice.actions;

export default flowInfoSlice.reducer;
